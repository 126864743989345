<script setup lang="ts">
const activeClass = ref('firstColumn');
</script>

<template>
  <section id="about" class="about">
    <h2 class="about-title font--h2">What we do</h2>

    <div class="about-box-wrapper">
      <div
        :class="['about-box', { active: activeClass === 'firstColumn' }]"
        @click="activeClass = 'firstColumn'"
      >
        <h3
          class="about-box-title font--h2"
          :class="[
            'about-box-title',
            'about-box-title-first',
            'font--h2',
            activeClass === 'firstColumn'
              ? 'text--color-main active'
              : 'text--monochrome-grey-50',
          ]"
        >
          Innovate
        </h3>
        <p
          class="about-box-text font--h4 text--monochrome-bl"
          :class="{ active: activeClass === 'firstColumn' }"
        >
          We create unique services for the ultimate Web3 experience, helping
          end-users save time and manage their assets more efficiently.
        </p>
      </div>

      <div
        :class="['about-box', { active: activeClass === 'secondColumn' }]"
        @click="activeClass = 'secondColumn'"
      >
        <h3
          class="about-box-title font--h2"
          :class="[
            'about-box-title',
            'about-box-title-second',
            'font--h2',
            activeClass === 'secondColumn'
              ? 'text--color-main active'
              : 'text--monochrome-grey-50',
          ]"
        >
          Build
        </h3>
        <p
          class="about-box-text font--h4 text--monochrome-bl"
          :class="{ active: activeClass === 'secondColumn' }"
        >
          Our team builds Web3-focused projects: decentralized wallet, news
          aggregator, and other blockchain software, so there’s nothing we can’t
          turn into code.
        </p>
      </div>

      <div
        :class="['about-box', { active: activeClass === 'thirdColumn' }]"
        @click="activeClass = 'thirdColumn'"
      >
        <h3
          class="about-box-title font--h2"
          :class="[
            'about-box-title',
            'about-box-title-third',
            'font--h2',
            activeClass === 'thirdColumn'
              ? 'text--color-main active'
              : 'text--monochrome-grey-50',
          ]"
        >
          Contribute
        </h3>
        <p
          class="about-box-text font--h4 text--monochrome-bl"
          :class="{ active: activeClass === 'thirdColumn' }"
        >
          SwitchCase believes the Web3 industry has tools for everyone – we
          strive to make them comprehensive and simple in use.
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.about {
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-title {
    padding: 140px 0 80px;
  }

  &-box {
    cursor: pointer;
    margin-top: 66px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 2px solid var(--monochrome-grey-50);
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom: none;
    height: 686px;
    padding: 60px 120px;
    transition: all 0.5s ease;
    width: 350px;

    &.active {
      margin: 0;
      gap: 24px;
      width: 100%;
      height: 100%;
      background-color: var(--blue-blue-50);
      padding: 106px 80px;
      height: 752px;
      transition: all 0.5s ease;
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
    }

    &-title {
      &-first {
        transform: translate(-36%, 111%) rotate(-90deg);
      }

      &-second {
        transform: translate(-25%, 46%) rotate(-90deg);
      }

      &-third {
        transform: translate(-39%, 170%) rotate(-90deg);
      }

      & {
        transition: all 0.3s ease-out;
      }

      &.active {
        transform: translate(0, 0) rotate(0);
        transition: all 0.3s ease-out;
      }
    }

    &-text {
      opacity: 0;
      visibility: hidden;
      max-width: 880px;
      transition: unset;

      &.active {
        opacity: 1;
        visibility: visible;
        transition: all 1.2s ease-out;
      }
    }
  }
}

@media (max-width: 1700px) {
  @include tablet-fonts;

  .about {
    &-title {
      padding: 120px 0 80px;
    }

    &-box {
      padding: 60px 80px;
      width: 250px;

      &-text {
        font-size: 56px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
      }
    }
  }
}

@media (max-width: 1300px) {
  .about {
    &-title {
      padding: 80px 0;
    }

    &-box {
      &-text {
        font-size: 46px;
      }
    }
  }
}

@media (max-width: 1130px) {
  @include mobile-fonts;

  .about {
    &-box {
      margin-top: 88px;

      height: 352px;
      padding: 60px 16px;
      width: 70px;

      &.active {
        gap: 16px;

        padding: 24px;
        height: 440px;
      }

      &-title {
        &.active {
          transition: all 0.2s ease-out;
        }
      }

      &-text {
        &.active {
          transition: opacity 1.5s ease;
        }
      }
    }
  }
}
</style>
