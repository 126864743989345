<script></script>

<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="32"
      height="32"
      rx="16"
      transform="matrix(-1 0 0 1 32 0)"
      fill="#E4E6F1"
    />
    <path
      d="M14.7598 7.90662L6.66643 16L14.7598 24.0933"
      stroke="#243AFF"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25 16L7 16"
      stroke="#243AFF"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style scoped></style>
