import revive_payload_client_EoiqoDcYWH from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_6pzylf5pxyueto774dtq5sstye/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_UFlOxcamBd from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_6pzylf5pxyueto774dtq5sstye/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_E4EwgNgwzj from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_6pzylf5pxyueto774dtq5sstye/node_modules/nuxt/dist/app/plugins/router.js";
import payload_client_4RiZBxNI10 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_6pzylf5pxyueto774dtq5sstye/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_U4sedH6Rwc from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_6pzylf5pxyueto774dtq5sstye/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wr7TYwUIHn from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_6pzylf5pxyueto774dtq5sstye/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2IG4hse6a2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_6pzylf5pxyueto774dtq5sstye/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import sentry_client_Shhzlzmtjy from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._6vctzoedlatevbbdkzt5lgfevu/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
export default [
  revive_payload_client_EoiqoDcYWH,
  unhead_UFlOxcamBd,
  router_E4EwgNgwzj,
  payload_client_4RiZBxNI10,
  navigation_repaint_client_U4sedH6Rwc,
  check_outdated_build_client_wr7TYwUIHn,
  chunk_reload_client_2IG4hse6a2,
  components_plugin_KR1HBZs4kY,
  sentry_client_Shhzlzmtjy,
  sentry_client_config_o34nk2sJbg
]